<template>
  <div style="height: 100%">
    <el-container style="height: 100%">
      <!-- / 左侧菜单 -->
      <el-aside style="width: auto !important">
        <frAside></frAside>
      </el-aside>
      <el-container>
        <!-- / 头部标题 -->
        <el-header></el-header>

        <el-main class="fr_main_div">
          <el-row :gutter="35">
            <el-col :lg="15" :sm="24">
              <div class="doc_traty" v-html="treaTy.tre_center"></div>
            </el-col>
            <el-col :lg="9"> </el-col>
          </el-row>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import { GetfrDoc } from "../../components/api";
import axios from "axios";
import aside from "../../components/aside.vue";
export default {
  components: {
    frAside: aside,
  },
  data() {
    return {
      treaTy:{}
    };
  },
  mounted() {
    this.frdoc();
  },

  methods: {
    frdoc() {
      axios
        .get(GetfrDoc)
        .then((res) => {
          this.treaTy = res.data.treatys;
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
<style scoped>
/* 问题列表 */
.master_list_bg {
  background: #eef2fd !important;
}
.master_list {
  display: flex;
  justify-content: end;
  margin-bottom: 35px;
}
.problem_list_questions_center {
  padding: 14px;
  background: #fef2f3;
  border-radius: 11px;
  margin-left: 10px;
  max-width: 60%;
}
.problem_list_questions_img {
  width: 33px;
  height: 33px;
  background: #e0e0e0;
  border-radius: 50%;
  opacity: 1;
}
.problem_list_questions {
  display: flex;
  width: 100%;
  margin-bottom: 35px;
}
.problem_user_online {
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 6px;
  background: #5ae28e;
  border-radius: 50%;
}
.problem_user_img {
  position: relative;
  width: 32px;
  height: 32px;
  background: #ed7777;
  border-radius: 50%;
  right: 0;
}
.problem_user_emi {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  overflow: hidden;
}
.problem_user_about {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  margin-right: 10px;
  overflow: hidden;
}
.problem_user {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.problem_title {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
}
.problem_div {
  background-color: #ffffff;
  border-radius: 18px;
  padding: 24px;
}

/* 最新 */
.list_h {
  color: #111026;
  margin: 35px 0;
}

/* 文章内容 */
.doc_center {
  background: #ffffff;
  padding: 24px;
  border-radius: 18px;
}
.doc_traty {
  background: #52AAC4;
  border-radius: 18px;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  padding: 30px 66px;
}

/* macin主体内容 */
.fr_main_div .el-header {
  background: rgb(238, 240, 227);
}

.el-aside {
  background-color: #ffffff;
}
/* css3媒体查询 */
@media (max-width: 760px) {
  .doc_traty{
    padding: 30px 30px;
  }
  .list_h{
    margin: 15px 0px;
  }
}
/* 深色模式 */
@media (prefers-color-scheme: dark) { 
  .el-main,.el-header { 
    background-color: #1f2a38 !important;
  }
}
</style>